import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import '../styles/geekhub-layout.css';

const Wrapper = styled.div`
  @font-face {
    font-family: gotham;
    font-display: auto;
    font-weight: normal;
    src: url(/fonts/7569Gotham.woff) format("woff");
  }
  line-height: 1.5;
  min-height: 100%;
  margin-bottom: -73px;
`;

const Header = styled.div`
  background-image: url('/images/02_bg_geekhub.jpg');
  background-position: 50% 70%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  z-index: 2;
  position: relative;
  
  @media screen and (max-width: 480px) {
    background-size: cover;
  }
  
  &:before {
    display: block;
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 72, 94, .6);
  }

  .header-titles-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1190px;
    padding: 20px;
    align-items: center;
    text-align: center;
  }
  .site-logo img {
    height: 30px;
    width: auto;
    @media screen and (max-width: 480px) {
      height: 26px;
    }
  }
  .header-links svg {
    line-height: 18px;
    width: 21px;
    color: #fff;
    font-size: 13px;
    height: 24px;
    margin-left: 7px;
  }
  .entry-header {
    padding: 40px 0;
    color: #fff;
    text-align: center;
  }
  .section-inner {
    width: calc(100% - 80px);
    max-width: 1190px;
    margin-right: auto;
    margin-left: auto;
  }
  h1 {
    font-family: 'Gotham', sans-serif;
    font-size: 68px;
    margin: 0;
    line-height: 1.14;

    @media (max-width: 700px) {
      font-size: 40px;
    }
    @media (max-width: 568px) {
      font-size: 28px;
    }
    @media (max-width: 340px) {
      font-size: 22px;
    }
  }
`;

const Content = styled.div`
  
  .section-inner.medium {
    max-width: 1000px;
  }
  
  
  .post-inner {
    max-width: 1200px;
    padding: 60px 20px;
    margin-right: auto;
    margin-left: auto;
    font-size: 21px;
    line-height: 1.5;
    color: #0c485e;
    font-family: gotham, sans-serif;

    @media screen and (max-width: 480px) {
      padding: 40px 20px;
      font-size: 20px;
    }
    
    .columns {
      column-count: 2;
      column-gap: 50px;
      text-align: justify;
      
      @media screen and (max-width: 568px) {
        column-count: 1;
        column-gap: 0;
      }
    }
    
    p {
      line-height: 1.46;
      margin-bottom: 1.5rem;
      
      &.congrats {
        font-size: 25px;
        font-weight: 800;
        
        @media screen and (max-width: 480px) {
          font-size: 22px;
        }
      }
    }
    .m-custom {
      margin-bottom: 0.5rem;
    }

    a {
      color: #08BDD7;
      text-decoration-line: none;
    }
    
    ul {
      padding-top: 0;
      list-style: decimal;
      
      li {
        margin-bottom: calc(1.15rem / 2);
      }
    }
  }
`;

const Footer = styled.div`
  margin-bottom: -73px;
  height: 73px;
  background-color: #0c485e;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Gotham", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  
  p {
    padding-bottom: 0;
    margin-bottom: 0;
    
    @media screen and (max-width: 480px) {
      line-height: 1.4;
    }
  }
  
  a {
    color: #08BDD7;
    text-decoration-line: none;
  }
`;

const Push = styled.div`
  height: 73px;
`;

const Internship = () => (
  <>
    <SEO
      title="New Normal loves Geekhub"
      description="We build custom web and mobile apps for business."
      keywords={['web', 'apps', 'Geekhub']}
    />
    <Wrapper>
      <Header id="site-header" className="header-footer-group">
        <div className="header-titles-wrapper">
          <div className="header-titles">
            <div className="site-logo faux-heading"><a href="https://newnormal.agency/" className="custom-logo-link" rel="home" aria-current="page"><img width="467" height="93" src="https://geekhub.newnormal.agency/wp-content/uploads/2022/02/cropped-download.png" className="custom-logo" alt="New Normal loves Geekhub" sizes="(max-width: 467px) 100vw, 467px" /></a>
            </div>
          </div>
          <div className="header-links">
            <a href="https://www.instagram.com/n3wnormal/?hl=en" target="_blank" rel="noreferrer">
              <svg className="svg-inline--fa fa-instagram fa-w-14 fa-lg" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>
            <a href="https://www.facebook.com/N3wNormal" target="_blank" rel="noreferrer">
              <svg className="svg-inline--fa fa-facebook-f fa-w-10 fa-lg" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title">Вітаємо із завершенням 11&nbsp;сезону&nbsp;Geekhub</h1>
          </div>
        </div>
      </Header>
      <Content id="site-content">
        <div className="post-inner">
          <p className="congrats">Привіт, друже</p>
          <div className="columns">
            {/* eslint-disable-next-line max-len */}
            <p>Вітаємо тебе із закінченням навчання в Geekhub. Це був довгий рік наповнений завдань та викликів, запитань та безсонних ночей. Ми раді, що не дивлячись на це все, ти зміг завершити непростий сезон в Geekhub. Сподіваємося, ти зміг здобути нові навички та вміння.</p>
            {/* eslint-disable-next-line max-len */}
            <p>Цього року ми надаємо можливість, окремим випускникам Geekhub, пройти інтернатуру у команді New Normal. Ця програма – можливість, для молодих людей, навчитися мистецтву створення програмних продуктів в умовах реального світу і під керівництвом кращих інженерів нашої країни.</p>
            {/* eslint-disable-next-line max-len */}
            <p>New Normal – компанія заснована в Україні, з офісами у Черкасах, Дюссельдорфі, Сіетлі. Вже більше 10 років ми допомагаємо своїм клієнтам будувати наступне покоління цифрових продуктів.</p>
            {/* eslint-disable-next-line max-len */}
            <p>Подай заявку на участь у програмі інтернатури – це логічний наступний крок для застосування своїх навичок до умов справжнього ринку. Наша команда допоможе тобі здобути впевненість у своїх зусиллях, відкриє горизонти подальшого розвитку, а можливо, і відкриє двері, щоб приєднатися до постійних членів команди New Normal.</p>
            <p className="m-custom">Для цього необхідно:</p>
            <ul>
              <li>Заповни онлайн форму, щоб надати нам деталі про себе. Форма <a href="https://docs.google.com/forms/d/e/1FAIpQLSfirpOQFHrPDwoBUbP4a1Evqh1yQBpxmtvCEPIIdhc5X1vKVA/viewform" target="_blank" rel="noreferrer">тут</a></li>
              <li>Пройди співбесіду.</li>
              <li>Отримай запрошення приєднатися до інтернів New Normal</li>
            </ul>
            <p>Слідкуй за цим посиланням. І тут буде більше деталей.</p>
          </div>
        </div>
      </Content>
      <Push />
    </Wrapper>
    <Footer>
      <p>©
        2022 <a href="https://newnormal.agency/" target="_blank" rel="noreferrer">New&nbsp;Normal </a>
        All&nbsp;rights&nbsp;reserved.
      </p>
    </Footer>
  </>
);

export default Internship;
